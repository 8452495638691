import styles from './spacer.module.scss';

/**
 * Spacer element
 * @param type String for type of spacer, name is also css style that is applied
 * @returns Span element with spacer style css
 */
const Spacer = ({ type = 'default' }: {
    type?: string,
}) => {
    return (
        <span className={styles[type]} />
    )
}

export default Spacer;