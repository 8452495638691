import styles from './heading.module.scss'

/**
 * Heading component, used to show headings and sub headings
 * @param content - {title: string, subtitle: string} Title and subtitle text
 * @param h1 - defaults false | sets title to be h1 or h2
 */
const Heading = ({ content, h1 = false }: {
    /**
     * Title and subtitle text
     */
    content: {
        title: string,
        subtitle?: string,
    },
    h1?: boolean

}) => {

    return (
        <div className={styles.headingContainer}>
            { (h1) ? 
                <h1 className={styles.heading} dangerouslySetInnerHTML={{ __html: content.title }} /> :
                <h2 className={styles.heading} dangerouslySetInnerHTML={{ __html: content.title }} />
            }
            {
                content.subtitle && 
                <h3 className={styles.h3} dangerouslySetInnerHTML={{ __html: content.subtitle }} />
            }
            
        </div>
    )
}

export default Heading;
