import styles from './text.module.scss'

/**
 * Text component for displaying paragraphs
 * @param content String of text content
 * @param margin margin styles to change css if needed
 * @returns <p> element with styles and text contnet
 */
const Text = ({ content, margin = "default" }: {
    content: string,
    margin?: string
}) => {

    return (
        <p
            className={`${styles.p} ${styles[margin]}`}
            dangerouslySetInnerHTML={{ __html: content }}
        />
    )
}

export default Text;
