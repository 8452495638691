import styles from './container.module.scss'

/**
 * container component, used to wrap components together within sections
 * @param children All child components for the continer
 * @param longer Top | Bottom | Both | none - adds extra margin is the container needs more space. defaults to none
 * @param soloHeader defaults false - For when there is no Text content
 * @param style inline style attributes
 * @returns Container element with children
 */
const Container = ({ children, longer = 'none', soloHeader = false, style }: {
    children: any,
    longer?: string,
    soloHeader?: boolean,
    style?: any

}) => {

    return (
        <>
            <div
                data-testid="container"
                className={`${styles.container} ${styles[longer]} ${soloHeader && styles.soloheader}`}
                style={style}
            >
                {children}
            </div>
        </>
    )
}

export default Container;
