import styles from './list.module.scss'

import { ReactElement, useMemo } from 'react';

/**
 * List component, used to render lists from database collumn
 * @param content  String array of lsit content
 * @param ul Boolean to delect between <ul> and <ol>, defaults to ul
 * @param underHeading Boolean to add styles if it lives under heading
 * @returns list container div element with list inside
 */
const List = ({ content, ul = true, underHeading = false }: {
    content: string[],
    ul?: boolean,
    underHeading?: boolean
}) => {

    const listHTML = useMemo<ReactElement[]>((): ReactElement[] => {
        if (!content) return []; // Guard against empty
        return content.map((item, index) => {
            return <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
        })
    }, [content])


    return (
        <>
            <div className={`${styles.listContainer} ${underHeading && styles.listContainerUnderHeading}`}>
                {(ul) ?
                    <ul className={`${styles.listTitle} ${styles.list}`}>
                        {listHTML}
                    </ul>
                    :
                    <ol className={`${styles.list} ${styles.leftalign}`}>
                        {listHTML}
                    </ol>
                }
            </div>
        </>
    )
}

export default List;