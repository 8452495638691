/* eslint-disable jsx-a11y/alt-text */
/* jsx-a11y/alt-text is disabled for this file because it is incorrectly
 * causing an issue with the picture img element
*/

import Image, { getImageProps } from 'next/image'
import dynamic from 'next/dynamic'

import styles from './imagebanner.module.scss';

import type { pageTitle } from '@lib/types';
import crocLogo from '@public/images/logo-white.png'
import { useRef } from 'react';
const BookingWidget = dynamic(() => import('@components/BookingComponent'));


const ImageBanner = ({ title, isHome = false, subColor = "khaki" }: {
    title: pageTitle
    isHome?: boolean,
    subColor?: string, //sub title color class
}) => {
    const scroll = useRef(null);

    // Common image variables
    const common = {
        priority: true,
        className: styles.img,
        fill: true,
        alt: title.banner[0].alt,
        //placeholder: 'blur' - Cannot use placeholder in next 14.1 with getImageProps 
    }

    // Desktop image srcset
    const {
        props: {
            srcSet: desktop
        }
    } = getImageProps({
        ...common,
        src: title.banner[0].path,
    });

    // Mobile image srcset
    const {
        props: {
            srcSet: mobile,
            ...rest
        }
    } = getImageProps({
        ...common,
        src: title.banner[1].path,
    });

    return (

        <div ref={scroll} className={styles.container} >

            <picture>
                <div className={styles.blurContainer}>
                    <div className={styles.blurDiv} style={title.banner[0].cssBlur} />
                </div>
                <source media="(min-width: 769px)" srcSet={desktop} />
                <source media="(max-width: 768px)" srcSet={mobile} />
                <img fetchPriority="high" {...rest} />

            </picture>

            {isHome ?
                <>
                    <div className={styles.titleHome}>
                        <div className={styles.titlecontainerHome}>
                            <p className={styles.titleTextHome}>{title && title.heading}</p>
                            {(title && title.tagline) ? <p className={`${styles.subheadingHome} ${styles.subtitle} ${subColor}`}>{title && title.tagline}</p> : <></>}
                        </div>
                    </div>
                    <div className={styles.logocontainer}>
                        <Image
                            priority
                            src={crocLogo}
                            className={styles.logo}
                            width={523}
                            height={120}
                            alt="The Crocodile Hunter Lodge Logo"
                            placeholder='blur'
                        />
                    </div>
                    <BookingWidget />
                </>
                :
                <>
                    <div className={styles.title}>
                        <div className={styles.titlecontainer}>
                            <p className={styles.titleText}>{title && title.heading}</p>
                            {(title && title.tagline) ? <p className={`${styles.subheading} ${styles.subtitle} ${subColor}`}>{title && title.tagline}</p> : <></>}
                        </div>
                    </div>
                    <div className={styles.skip} onClick={() => {
                        window.scrollTo({ top: scroll.current.scrollHeight, left: 0, behavior: "smooth" });
                    }}>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                            <defs>
                                <linearGradient id="skip-gradient" x1="50%" y1="0%" x2="50%" y2="100%" >

                                    <stop offset="0%" stopColor="#c1b6a7">
                                        <animate attributeName="stop-color" values="#c1b6a7; #F2EFEC; #c1b6a7" dur="10s" repeatCount="indefinite"></animate>
                                    </stop>

                                    <stop offset="100%" stopColor="#F2EFEC">
                                        <animate attributeName="stop-color" values="#F2EFEC; #c1b6a7; #F2EFEC" dur="10s" repeatCount="indefinite"></animate>
                                    </stop>

                                </linearGradient>
                            </defs>
                            <path fill="url('#skip-gradient')" d="M 12 0 z z m 5.247 8 l -5.247 6.44 l -5.263 -6.44 l -0.737 0.678 l 6 7.322 l 6 -7.335 l -0.753 -0.665 z" />
                        </svg>
                    </div>
                </>
            }

        </div>

    )
}

export default ImageBanner;