import Link from 'next/link'
import styles from './linkComponent.module.scss'

/**
 * List component, used to render lists from database collumn
 * @param link1 Link 1 text and url
 * @param link2 link 2 text and url
 * @param onClick Optional - function to run on click
 * @param target_blank boolean - _blank or _self in link. default _self
 * @returns individual link, or double link container
 */
const LinkComponent = ({ link1, link2 = null}: {
    link1: {
        text: string,
        url: string,
        onClick?: () => void,
        target_blank?: boolean
    },
    link2?: {
        text: string,
        url: string,
        onClick?: () => void,
        target_blank?: boolean
    }
}) => {

    return (
        <>
            {(link2 == null) ?
                <Link
                    href={link1.url}
                    onClick={link1.onClick && link1.onClick}
                    target={(link1.target_blank) ? "_blank" : "_self"}
                    className={`${styles.link}`}>
                    {link1.text}
                </Link>
                :
                <div className={styles.doublelinkcontainer}>
                    <Link
                        href={link1.url}
                        className={`${styles.link}`}
                        onClick={link1.onClick && link1.onClick}
                        target={(link1.target_blank) ? "_blank" : "_self"}>
                        {link1.text}
                    </Link>
                    <hr className={styles.hr} />
                    <Link
                        href={link2.url}
                        className={`${styles.link}`}
                        onClick={link2.onClick && link2.onClick}
                        target={(link2.target_blank) ? "_blank" : "_self"}>
                        {link2.text}
                    </Link>
                </div>
            }
        </>
    )
}

export default LinkComponent;



