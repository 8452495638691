import { useMemo } from 'react';
import styles from './grid.module.scss'

/**
 * Flex-box grid element to display items.
 * Once at mobile size it will stack the elements instead.
 * @param children Child elements to be passed into grid
 * @param columns Int number of columns in the grid
 * @param extraClass string of any extra css classes
 * @returns Grid Element
 */
const Grid = ({ children, columns , extraClass=[""] }: {
    children: any,
    columns: number,
    extraClass?: string[]
}) => {

    //Max columns 6
    if (columns > 6) columns = 6;

    const extraClasses: string = useMemo(() => {
        let string: string = "";
        extraClass.forEach(item => {
            string += ` ${styles[item]}`;
        });
        return string;
    }, [extraClass]);
    
    return (
        <>
            <div 
                data-testid="grid" 
                className={`${styles.grid} ${extraClasses}`}
                style={{gridTemplateColumns: `repeat(${columns}, 1fr)`}}
            >
                {children}
            </div>
        </>
    )
}

export default Grid; 