import styles from './section.module.scss'

/**
 * Section component, used as the top level component for wrapping every section in a page. It can have a flat colour or an image for a background.
 * @param children All child components for the section
 * @param colour the background colour and font colour styling
 * @param size Section height styling (defaultsize | long | short)
 * @param id HTML id for the section for anchor links
 * @returns Section component with styling and child elements
 */
const Section = ({ children, colour = "cream", size, id=''} : {
    children: any,
    colour: string,
    size?: string,
    id?: string

}) => {

    return (
        <>
            <section
                id={id}
                data-testid="section"
                className={`${styles.section} ${styles[colour]} ${size && styles[size]}`}
            >
                    {children}
            </section>
        </>
    )
}

export default Section;
